export default class DtoTimeError {
    constructor(obj) {
        this.routingKey = obj.routingKey // String,
        this.url = obj.url // String,
        this.instance = obj.instance // Int,
        this.service = obj.service // Int,
        this.level = obj.level // Int,
        this.requestStart = obj.requestStart // DateTime,
        this.processStart = obj.processStart // DateTime,
        this.processEnd = obj.processEnd // DateTime,
        this.user = obj.user // String,
        this.module = obj.module // String,
        this.referer = obj.referer // Option[String] = None,
        this.userAgent = obj.userAgent // Option[String] = None,
        this.body = obj.body // Option[String] = None,
        this.size = obj.size // Option[String] = None
    }
}