import ApplicationConf from 'conf/ApplicationConf'
import { ServiceActionConstant } from 'service/reducers/ServiceReducer'
import { catchFunctionBuilder, genericPromise2, thenFunctionCreateBuilder, thenFunctionDeleteBuilder, thenFunctionUpdateBuilder } from 'utils/ActionUtils'


const ServiceAction = {
    fetchServices: () => dispatch => genericPromise2(ApplicationConf.service.all())
        .then((list = []) => dispatch(ServiceActionConstant.services(list)))
        .catch(catchFunctionBuilder('Erreur lors de la récupération des services', dispatch)),
    createService: service => dispatch => genericPromise2(ApplicationConf.service.all(), { body: service, method: 'POST' })
        .then(thenFunctionCreateBuilder('Service créé avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la création d\'un service', dispatch)),
    updateService: service => dispatch => genericPromise2(ApplicationConf.service.all(), { body: service, method: 'PUT' })
        .then(thenFunctionUpdateBuilder('Service modifié avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la modification d\'un service', dispatch)),
    deleteService: id => dispatch => genericPromise2(ApplicationConf.service.single(id), { method: 'DELETE' })
        .then(thenFunctionDeleteBuilder('Service supprimé avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la suppression d\'un service', dispatch)),
}

export default ServiceAction