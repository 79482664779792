import loading from 'assets/pictures/loading.gif'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const Wait = ({}) => {
    const {
        isWaiting,
    } = useSelector(store => ({
        isWaiting: store.WaitReducer.isWaiting,
    }), shallowEqual)

    return isWaiting && (
        <div
            style={{
                height: '100%',
                width: '100%',
                position: 'fixed',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    bottom: '0',
                    left: '50%',
                    right: '0',
                }}
            >
                <img src={loading} />
            </div>
        </div>
    )
}

export default Wait
