import React from 'react'
import { Alert, Snackbar, Stack, snackbarClasses } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'

const ToastrContainer = () => {
    const dispatch = useDispatch()
    const {
        listToast,
    } = useSelector(store => ({
        listToast: store.ToastrReducer.listToast,
    }), shallowEqual)

    // todo use notistack or react-toastify
    return (
        <Stack direction='column' alignItems='flex-start' spacing={1}>
            {
                listToast.map(({ id, message, severity }, i) => (
                    <Snackbar
                        key={id}
                        open={listToast.some(e => e.id === id)}
                        autoHideDuration={6000}
                        onClose={(_, reason) => reason !== 'clickaway' && dispatch(ToastActionConstant.hideOlder())}
                        sx={{ [`&.${snackbarClasses.root}`]: { bottom: `${i * 50 + 20}px` } }}
                    >
                        <Alert
                            variant='filled'
                            severity={severity}
                            sx={{ width: '100%' }}
                            // onClose={() => dispatch(ToastActionConstant.remove(id))}
                        >
                            {message}
                        </Alert>
                    </Snackbar>
                ))
            }
        </Stack>
    )
}

export default ToastrContainer
