module.exports = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    CANCEL: 'CANCEL',
    DELETE: 'DELETE',
    SAVE: 'SAVE',
    CLOCK: 'CLOCK',
    CLEAR: 'CLEAR',
    UPLOAD: 'UPLOAD',
    DOWNLOAD: 'DOWNLOAD',
    SOUND: 'SOUND',
    OTHER: 'OTHER',
}