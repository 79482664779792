/* eslint-disable react-hooks/exhaustive-deps */
import { HomeActionConstant } from 'home/reducers/HomeReducer'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useActions = (callback, dependencies = []) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const actions = callback()
        dispatch(HomeActionConstant.actions(actions))
    }, dependencies)

    useEffect(() => { // clean actions
        return () => dispatch(HomeActionConstant.actions([]))
    }, [])
}

export default useActions