import React from 'react'
import { Grid } from '@mui/material'
import aquaparkPng from 'assets/pictures/aquapark.png'

const LoginApp = () => {
    // const redirectURL = (window.location.href.includes('localhost') ? 'https%3A%2F%2Flocalhost%3A3002%3FslackRedirect%3Dtrue' : 'https%3A%2F%2Faquaparc.aquasys.fr%3FslackRedirect%3Dtrue')
    // .replaceAll('/', '%2F').replaceAll(':', '%3A').replaceAll('?', '%3F').replaceAll('=', '%3D')
    return (
        <Grid container alignItems='center' justifyContent='center' style={{ minHeight: '100vh', backgroundImage: `url(${aquaparkPng})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
            <Grid item>
                <div>
                    <a
                        href={`https://aquasysteam.slack.com/openid/connect/authorize?response_type=code&scope=openid%20profile%20email&client_id=110611211872.6631015215654&nonce=abcd&team=Aquasys&redirect_uri=https://aquaparc.aquasys.fr?slackRedirect=true`}
                        style={ { alignItems: 'center', color: '#fff', backgroundColor: '#4A154B', border: 0, borderRadius: 4, display: 'inline-flex', fontFamily: 'Lato, sans-serif', fontSize: 18, fontWeight: 600, height: 56, justifyContent: 'center', textDecoration: 'none', width: 296 } }>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            style={{ height: 24, width: 24, marginRight: 12 }}
                            viewBox='0 0 122.8 122.8'
                        >
                            <path d='M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z' fill='#e01e5a'></path><path d='M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z' fill='#36c5f0'></path><path d='M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z' fill='#2eb67d'></path><path d='M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z' fill='#ecb22e'></path>
                        </svg>
                        Sign in with Slack
                    </a>
                </div>
            </Grid>
        </Grid>
    )
}

export default LoginApp