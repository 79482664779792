import { maxBy } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    listToast: [],
}

const store = createSlice({
    name: 'toastr',
    initialState,
    reducers: {
        error: (state, action) => {
            state.listToast.push({ id: (maxBy(state.listToast, 'id')?.id ?? 0) + 1, message: action.payload, severity: 'error' })
        },
        success: (state, action) => {
            state.listToast.push({ id: (maxBy(state.listToast, 'id')?.id ?? 0) + 1, message: action.payload, severity: 'success' })
        },
        warning: (state, action) => {
            state.listToast.push({ id: (maxBy(state.listToast, 'id')?.id ?? 0) + 1, message: action.payload, severity: 'warning' })
        },
        info: (state, action) => {
            state.listToast.push({ id: (maxBy(state.listToast, 'id')?.id ?? 0) + 1, message: action.payload, severity: 'info' })
        },
        hideOlder: (state) => {
            state.listToast.shift()
        },
        remove: (state, action) => {
            state.listToast = state.listToast.filter(t => t.id !== action.payload)
        },
        clear: (state) => {
            state.listToast = []
        },
    },
})

export const ToastActionConstant = store.actions
export default store.reducer