/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useReducer, useRef } from 'react'
import { IDLE, LOADING, REJECTED, SUCCEEDED } from 'store/StoreConstants'
import { genericPromise2 } from 'utils/ActionUtils'
import useTimeout from './useTimeout'
import moment from 'moment'

const useFetchTimeout = (url, options, delay, timeoutOptions) => {
    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef(false)

    const {
        skipLoadingState = false,
        formatResult = r => r,
    } = timeoutOptions ?? {}

    const initialState = {
        error: undefined,
        data: undefined,
        resultDate: undefined,
        status: IDLE,
    }

    // Keep state logic separated
    const fetchReducer = (state, action) => {
        switch (action.type) {
            case 'loading':
                return { ...initialState, status: LOADING, data: state.data, resultDate: state.resultDate }
            case 'fetched':
                return { ...initialState, status: SUCCEEDED, data: action.payload, resultDate: moment().valueOf() }
            case 'error':
                return { ...initialState, status: REJECTED, error: action.payload, resultDate: moment().valueOf() }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    const fetchData = async () => {
        cancelRequest.current = false
        if (!skipLoadingState) {
            dispatch({ type: 'loading' })
        }

        try {
            const promiseResult = await genericPromise2(url, options)
                .then(formatResult)
                .catch(e => {
                    throw new Error(e)
                })

            if (cancelRequest.current) {
                return
            }

            dispatch({ type: 'fetched', payload: promiseResult })
        } catch (error) {
            if (cancelRequest.current) {
                return
            }

            console.error(error)
            dispatch({ type: 'error', payload: error.message })
        }
    }

    useEffect(() => {
        // Use the cleanup function for avoiding a possibly
        // state update after the component was unmounted
        if (url) {
            fetchData()
        }
        return () => {
            cancelRequest.current = true
        }
    }, [url])

    const { reset, clear } = useTimeout(() => {
        if (!url) {
            return
        }
        fetchData().finally(reset)
    }, delay)

    const resetTimeout = useCallback(() => {
        if (url) {
            fetchData()
        }
        reset()
    }, [url, reset])

    return {
        ...state,
        reset: resetTimeout,
        clear,
    }
}

export default useFetchTimeout