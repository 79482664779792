/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useRef } from 'react'
import { IDLE, LOADING, REJECTED, SUCCEEDED } from 'store/StoreConstants'
import { genericPromise2 } from 'utils/ActionUtils'

const useFetch = (url, options, fetchOptions) => {
    const cache = useRef({})

    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef(false)

    const {
        formatResult = r => r,
    } = fetchOptions ?? {}

    const initialState = {
        error: undefined,
        data: undefined,
        status: IDLE,
    }

    // Keep state logic separated
    const fetchReducer = (state, action) => {
        switch (action.type) {
            case 'loading':
                return { ...initialState, status: LOADING, data: state.data }
            case 'fetched':
                return { ...initialState, status: SUCCEEDED, data: action.payload }
            case 'error':
                return { ...initialState, status: REJECTED, error: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    const fetchData = async () => {
        cancelRequest.current = false
        dispatch({ type: 'loading' })

        try {
            const result = await genericPromise2(url, options)
                .then(formatResult)
                .catch(e => {
                    throw new Error(e)
                })

            cache.current[url] = result
            if (cancelRequest.current) {
                return
            }

            dispatch({ type: 'fetched', payload: result })
        } catch (error) {
            if (cancelRequest.current) {
                return
            }

            dispatch({ type: 'error', payload: error.message })
        }
    }

    useEffect(() => {
        // Do nothing if the url is not given
        if (!url) {
            return
        }

        // If a cache doesn't exists for this url, fetch data
        if (!cache.current[url]) {
            fetchData()
        }

        // Use the cleanup function for avoiding a possibly
        // state update after the component was unmounted
        return () => {
            cancelRequest.current = true
        }
    }, [url])

    return {
        ...state,
        reload: fetchData,
    }
}

export default useFetch