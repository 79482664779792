import { useCallback, useEffect, useState } from 'react'
import { Howl } from 'howler'

const useSound = (
    src,
    {
        // id,
        volume = 1,
        playbackRate = 1,
        soundEnabled = true,
        interrupt = false,
        onload,
        ...delegated
    } = {}
) => {
    const [sound, setSound] = useState()

    useEffect(() => {
        setSound(new Howl({
            src: Array.isArray(src) ? src : [src],
            volume,
            onload,
            ...delegated,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(src)]) // check if a string change

    useEffect(() => {
        if (sound) {
            sound.volume(volume)
            sound.rate(playbackRate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volume, playbackRate])

    const play = useCallback((options = {}) => {
        if (!sound || (!soundEnabled && !options.forceSoundEnabled)) {
            return
        }

        if (interrupt) {
            sound.stop()
        }
        const rate = options.playbackRate || playbackRate
        if (rate) {
            sound.rate(rate)
        }

        sound.play(options.id)
    }, [sound, soundEnabled, interrupt, playbackRate])

    const stop = useCallback(id => {
        if (!sound) {
            return
        }
        sound.stop(id)
    }, [sound])

    const pause = useCallback(id => {
        if (!sound) {
            return
        }
        sound.pause(id)
    }, [sound])

    return [
        play,
        {
            sound,
            stop,
            pause,
        },
    ]
}

export default useSound