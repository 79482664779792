import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, buttonClasses, gridClasses } from '@mui/material'
import { ceil, range } from 'lodash'
import Select from '../form/Select'
import { darkBlue } from 'AppTheme'

const nbPerPageLabel = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: 'Tout', value: -1 },
]

const getMiddleRange = (nbPage, currentPage) => {
    if (currentPage < 4) {
        return range(3, 6)
    }
    if (currentPage > nbPage - 3) {
        return range(nbPage - 4, nbPage - 1)
    }
    return range(currentPage - 1, currentPage + 2)
}

const StyledButton = (props) => (
    <Button
        {...props}
        sx={{
            [`&.${buttonClasses.root}`]: {
                // eslint-disable-next-line react/prop-types
                backgroundColor: props.disabled ? '#DFDFDF' : darkBlue,
                minWidth: '40px',
                minHeight: '40px',
                maxWidth: '40px',
                maxHeight: '40px',
                padding: '0',
            },
            [`&.${buttonClasses.contained}`]: {
                fontSize: '13',
                // eslint-disable-next-line react/prop-types
                color: props.disabled ? '#9F9F9F' : 'white',
            },
        }}
        variant='contained'
        color='primary'
    />
)

const PageButton = ({
    nb = 1,
    currentPage = 1,
    setPage = () => { },
}) => {
    return (
        <Grid item xs='auto'>
            <StyledButton disabled={currentPage === nb} onClick={() => setPage(nb - 1)}>{nb}</StyledButton>
        </Grid>
    )
}

PageButton.propTypes = {
    nb: PropTypes.number,
    currentPage: PropTypes.number,
    setPage: PropTypes.func,
}

const TreeDotButton = () => (
    <Grid item xs='auto'>
        <StyledButton>...</StyledButton>
    </Grid>
)

const LeftArrowButton = ({
    currentPage = 1,
    setPage = () => { },
}) => (
    <Grid item xs='auto'>
        <StyledButton disabled={currentPage === 1} onClick={() => setPage(p => p - 1)}>{'<'}</StyledButton>
    </Grid>
)

LeftArrowButton.propTypes = {
    currentPage: PropTypes.number,
    setPage: PropTypes.func,
}

const RightArrowButton = ({
    currentPage = 1,
    nbPage = 1,
    setPage = () => { },
}) => (
    <Grid item xs='auto'>
        <StyledButton disabled={currentPage === nbPage} onClick={() => setPage(p => p + 1)}>{'>'}</StyledButton>
    </Grid>
)

RightArrowButton.propTypes = {
    currentPage: PropTypes.number,
    nbPage: PropTypes.number,
    setPage: PropTypes.func,
}

const PaginationButtons = ({
    page = 0,
    setPage = () => { },

    nbPage = 1,
}) => {
    const currentPage = page + 1
    if (nbPage <= 7) {
        const rangePage = range(1, nbPage + 1)
        return (
            <Grid container spacing={0.5}>
                {
                    rangePage.map(nb => (<PageButton key={nb} nb={nb} currentPage={currentPage} setPage={setPage} />))
                }
            </Grid>
        )
    }
    const middleRange = getMiddleRange(nbPage, currentPage)
    return (
        <Grid container spacing={0.5}>
            {/* arrow left */}
            <LeftArrowButton currentPage={currentPage} setPage={setPage} />
            {/* first button */}
            <PageButton nb={1} currentPage={currentPage} setPage={setPage} />
            {/* second button */}
            {currentPage > 4 && (<TreeDotButton />)}
            {currentPage <= 4 && (<PageButton nb={2} currentPage={currentPage} setPage={setPage} />)}
            {/* middle buttons */}
            {middleRange.map(nb => (<PageButton key={nb} nb={nb} currentPage={currentPage} setPage={setPage} />))}
            {/* sixth button */}
            {currentPage + 3 < nbPage && (<TreeDotButton />)}
            {currentPage + 3 >= nbPage && (<PageButton nb={nbPage - 1} currentPage={currentPage} setPage={setPage} />)}
            {/* seventh button */}
            <PageButton nb={nbPage} currentPage={currentPage} setPage={setPage} />
            {/* arrow right */}
            <RightArrowButton currentPage={currentPage} nbPage={nbPage} setPage={setPage} />
        </Grid>
    )
}

PaginationButtons.propTypes = {
    page: PropTypes.number,
    setPage: PropTypes.func,
    nbPage: PropTypes.number,
}

const TablePagintation = ({
    page = 0,
    setPage = () => { },

    rowsPerPage = 10,
    setRowsPerPage = () => { },

    nbRow = 0,
}) => {
    return (
        <Grid container spacing={3} alignItems='center' style={{ marginTop: '5px' }}>
            <Grid item xs={2} sx={{ [`&.${gridClasses.item}`]: { paddingTop: '0px' } }}>
                <Select
                    value={rowsPerPage}
                    items={nbPerPageLabel}
                    onChange={setRowsPerPage}
                />
            </Grid>
            {
                rowsPerPage > 0 && nbRow > rowsPerPage && (
                    <Grid item xs='auto' sx={{ [`&.${gridClasses.item}`]: { paddingTop: '0px' } }}>
                        <PaginationButtons
                            page={page}
                            setPage={setPage}

                            nbPage={ceil(nbRow / rowsPerPage)}
                        />
                    </Grid>
                )
            }
        </Grid>
    )
}

TablePagintation.propTypes = {
    page: PropTypes.number,
    setPage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    setRowsPerPage: PropTypes.func,
    nbRow: PropTypes.number,
}

const PaginatedList = ({
    nbElements,
    nbElementsByPage = 20,
    children,
}) => {
    const [page, setPage] = useState(0)

    const startId = page * nbElementsByPage
    const endId = (page + 1) * nbElementsByPage

    const nbPage = ceil(nbElements / nbElementsByPage)

    return (
        <>
            {children(startId, endId)}
            {
                nbPage > 1 && (
                    <div style={{ margin: '5 10' }}>
                        <PaginationButtons
                            page={page}
                            setPage={setPage}

                            nbPage={nbPage}
                        />
                    </div>
                )
            }
        </>
    )
}

PaginatedList.propTypes = {
    nbElements: PropTypes.number,
    nbElementsByPage: PropTypes.number,
    children: PropTypes.func,
}

export {
    TablePagintation,
    PaginationButtons,
    PaginatedList,
}