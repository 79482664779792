const readLocalStorage = (key) => {
    const storeValue = window.localStorage.getItem(key)
    if (storeValue !== null) {
        try {
            return JSON.parse(decodeURIComponent(window.atob(storeValue)))
        } catch (_) {
            return decodeURIComponent(window.atob(storeValue))
        }
    }
}

const writeLocalStorage = (key, value) => {
    if (value === undefined) {
        return window.localStorage.removeItem(key)
    }
    window.localStorage.setItem(key, window.btoa(encodeURIComponent(JSON.stringify(value))))
}

export {
    readLocalStorage,
    writeLocalStorage,
}