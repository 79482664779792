import React from 'react'
import { DateTimeField } from '@mui/x-date-pickers'
import moment from 'moment'
import { isNaN, isNil } from 'lodash'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

// todo create theme for default props
const DateTimePicker = ({
    label = '',
    value,
    onChange = () => {},
    onFocus,
    onBlur,
    focused,
}) => {
    return (
        <DateTimeField
            format='DD/MM/YYYY HH:mm'

            label={label}
            value={isNil(value) ? null : moment(value)}
            onChange={v => {
                const timestamp = v?.valueOf()
                if (isNaN(timestamp) || isNil(timestamp)) {
                    return
                }
                onChange(timestamp)
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            focused={focused}
            error={false}
            InputProps={{
                endAdornment:
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => onChange(undefined)}
                            edge='end'
                        >
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>,
            }}
        />
    )
}

DateTimePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    focused: PropTypes.bool,
}

export default DateTimePicker