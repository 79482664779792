import React from 'react'
import { Button, DialogActions, DialogTitle, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LoopIcon from '@mui/icons-material/Loop'
import PropTypes from 'prop-types'

const BasicDialogTitle = ({
    close = () => {},
    children = '',
}) => (
    <DialogTitle>
        <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
            <Grid item >
                {children}
            </Grid>
            <Grid item>
                <CloseIcon fontSize='large' onClick={close} style={{ cursor: 'pointer' }}/>
            </Grid>
        </Grid>
    </DialogTitle>
)

BasicDialogTitle.propTypes = {
    close: PropTypes.func,
    children: PropTypes.any,
}

const BasicDialogAction = ({
    validate = () => {},
    isLoading = false,
    isDisable = false,
    buttonLabel = 'Valider',
    children,
}) => (
    <DialogActions>
        <Grid container alignItems='center' justifyContent='right' columnSpacing={2} style={{ padding: '0 10' }}>
            <Grid item xs={9}>
                {children}
            </Grid>
            <Grid container item xs={3} justifyContent='flex-end'>
                <Button
                    onClick={validate}
                    variant='contained'
                    color='primary'
                    disabled={isLoading || isDisable}
                    startIcon={isLoading && (
                        <LoopIcon
                            sx={{
                                animation: 'spin 2s linear infinite',
                                '@keyframes spin': {
                                    '0%': {
                                        transform: 'rotate(360deg)',
                                    },
                                    '100%': {
                                        transform: 'rotate(0deg)',
                                    },
                                },
                            }}
                        />
                    )}
                >
                    {buttonLabel}
                </Button>
            </Grid>
        </Grid>
    </DialogActions>
)

BasicDialogAction.propTypes = {
    validate: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisable: PropTypes.bool,
    buttonLabel: PropTypes.string,
    children: PropTypes.any,
}

export {
    BasicDialogTitle,
    BasicDialogAction,
}