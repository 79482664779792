import { push } from '@lagunovsky/redux-react-router'
import { orderBy } from 'lodash'
import AppStore from 'store/AppStore'
// import SieauAction from '../components/sieau/SieauAction'
import { hasValue } from './NumberUtil'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'
import { WaitActionConstant } from 'wait/reducers/WaitReducer'


const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        AppStore.dispatch({ type: 'RESET_ALL' })
        AppStore.dispatch(push('/login'))
        // localStorage.removeItem(SIEAU_TOKEN)
        // AppStore.dispatch(HomeAction.disconnected('Une autre session a été ouverte avec votre compte'))
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    409: () => {
        throw new Error('409 Conflict')
    },
    429: () => {
        AppStore.dispatch(ToastActionConstant.error('Le nombre de tentatives de connexions est dépassé, votre compte a été bloqué. Veuillez contacter l\'administrateur.'))
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    },
}

const checkStatus = (obj, response) => {
    if (!obj[`${response.status}`]) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[`${response.status}`](response)
}

const checkPostgresStatus = (obj, response) => {
    const code = Object.keys(obj).find(element => `${response.status}` === `${element}`)
    if (response.status === 406) {
        return response
    }
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[code](response)
}

const fetchWithRetries = (url, options, retries = 2) => {
    const promise = fetch(url, options)
    return promise.then(response => {
        if (response.status === 502) {
            if (retries === 0) {
                return response
            }
            return fetchWithRetries(url, options, retries - 1)
        }
        return response
    })
}
const checkStatusBis = (obj, response) => {
    const code = Object.keys(obj).find(element => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.statusText}`)
    }
    return obj[code](response)
}


const checkAuthBis = (response, overrideStatus = {}) => {
    return checkStatusBis({
        ...codes,
        ...overrideStatus,
    }, response)
}


const checkAuth = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const checkPostgresAuth = (response, overrideStatus = {}) => {
    return checkPostgresStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const checkAuthWithoutKicking = (response, overrideStatus = { 403: () => {} }) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const checkError = (json = {}, errorCodeManagement = {}) => {
    if (json.error && errorCodeManagement[json.error]) {
        errorCodeManagement[json.error]()
    } else if (json.error) {
        AppStore.dispatch(WaitActionConstant.waitStop())
        throw new Error(json.error)
    }
    return json
}

const catchError = (err, message = '') => {
    AppStore.dispatch(WaitActionConstant.waitStop())
    // AppStore.dispatch(LogAction.logError(`${i18n.fetchError + message} : ${err}`))
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données : ${message} : ${err}`)
    AppStore.dispatch(ToastActionConstant.error(`${message}`))
}

const getJson = response => response.json()

const getText = response => response.text()

const getAuthorization = () => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('aquaparc-token')}`,
    // Authorization: 'Bearer bidon',
    Module: 'WEB',
    // Mode: 'no-cors',
})

const getPayload = () => {
    // const token = localStorage.getItem(SIEAU_TOKEN)
    // if (token) {
    //     return atob(token.split('.')[1])
    // }
    return ''
}

const getPutFetch = (route, data) => fetch(route, {
    method: 'PUT',
    headers: getAuthorization(),
    body: JSON.stringify(data),
}).then(checkAuth).then(getJson).then(checkError)

const promiseAllProgress = (promises, callback) => {
    let d = 0
    callback(0)
    promises.forEach((p) => {
        p.then(()=> {
            d++
            callback((d * 100) / promises.length)
        })
    })
    return Promise.all(promises)
}

const managePromises = (promises, dispatch, results, resolve, d, total, callbackProgress) => {
    if (promises.length) {
        if (AppStore.getState().SieauReducer.loadingData) {
            const p = promises.pop()
            p.then(r => {
                callbackProgress((d * 100) / total)
                managePromises(promises, dispatch, [...results, r], resolve, d+1, total, callbackProgress)
            })
        } else {
            // AppStore.getState().SieauReducer.abortController.abort()
            // dispatch(SieauAction.resetAbortSignal())
            resolve(results)
        }
    } else {
        // dispatch(SieauAction.setLoadingData(false))
        resolve(results)
    }
}

const promiseAllWithCancel = (promises, callbackProgress, dispatch) => {
    callbackProgress(0)
    // dispatch(SieauAction.setLoadingData(true))
    return new Promise((resolve) => managePromises(promises.reverse(), dispatch, [], resolve, 1, promises.length, callbackProgress))
}

const getAbortSignal = () => AppStore.getState().SieauReducer.abortSignal

const genericPromise2 = (url, options = {}, overrideStatus = {}) => {
    return fetch(url, {
        method: options.method ?? 'GET',
        body: options.body ? JSON.stringify(options.body) : undefined,
        headers: getAuthorization(),
        signal: options.signal,
    })
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const catchFunctionBuilder = (message = 'Erreur', dispatch) => err => {
    // eslint-disable-next-line no-console
    console.error(err)
    dispatch(ToastActionConstant.error(message))
}

const thenFunctionCreateBuilder = (message = 'Elément créé avec succès', dispatch) => res => {
    if (res.create === 0) {
        throw new Error('Aucun ajout effectué')
    }
    dispatch(ToastActionConstant.success(message))
    return res
}

const thenFunctionUpdateBuilder = (message = 'Elément créé avec succès', dispatch) => res => {
    if (res.update === 0) {
        throw new Error('Aucune modification effectué')
    }
    dispatch(ToastActionConstant.success(message))
    return res
}

const thenFunctionDeleteBuilder = (message = 'Elément créé avec succès', dispatch) => res => {
    if (res.delete === 0) {
        throw new Error('Aucune suppression effectué')
    }
    dispatch(ToastActionConstant.success(message))
    return res
}

const getStationArrowNav = (stationType, stations, currentId, onClick) => {
    const searchValues = AppStore.getState().AdministrationReducer.selectedSearchValues[stationType]
    const array = searchValues && searchValues.stations ? searchValues.stations : orderBy(stations, o => hasValue(o.name) ? o.name.toString().toUpperCase() : '}')
    return {
        currentId,
        array,
        labels: ['code', 'name'],
        onClick,
    }
}

const callPromisesSequentiallyRec = (listOfPromiseFunctions = [], listOfParams, callbackProgress, results, lengthTotal, resolved, callBackEnd = () => { }) => {
    if (!listOfPromiseFunctions.length) {
        callBackEnd(results)
    } else {
        const [headPromise, ...restPromises] = listOfPromiseFunctions
        const [headParams, ...restParams] = listOfParams
        if (AppStore.getState().SieauReducer.loadingData) {
            headPromise(...headParams).then(res => {
                callbackProgress(((resolved + 1) * 100) / lengthTotal)
                callPromisesSequentiallyRec(restPromises, restParams, callbackProgress, [...results, res], lengthTotal, resolved + 1, callBackEnd)
            })
        } else {
            callBackEnd(results)
        }
    }
}

const callPromisesSequentially = (listOfPromiseFunctions = [], listOfParams, callbackProgress, callBackEnd) => {
    callbackProgress(0)
    // AppStore.dispatch(SieauAction.setLoadingData(true))
    callPromisesSequentiallyRec(listOfPromiseFunctions, listOfParams, callbackProgress, [], listOfPromiseFunctions.length, 0, callBackEnd)
}

export {
    checkAuth, getJson, checkAuthBis, checkStatus, getAuthorization, getPayload, getText, checkError, getPutFetch, promiseAllProgress, fetchWithRetries, catchError,
    checkAuthWithoutKicking, getStationArrowNav, promiseAllWithCancel, getAbortSignal, checkPostgresAuth, callPromisesSequentially,
    genericPromise2, catchFunctionBuilder, thenFunctionCreateBuilder, thenFunctionUpdateBuilder, thenFunctionDeleteBuilder,
}
