import { FormControlLabel, Checkbox as CheckboxMui, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({
    checked = false,
    onToggle = () => {},
    label = '',
    labelStyle = {},
}) => {
    return (
        <FormControlLabel
            control={<CheckboxMui checked={checked} onChange={onToggle} inputProps={{ 'aria-label': 'controlled' }} />}
            label={<Typography sx={{ color: '#161832', fontWeight: 'bold', fontSize: '1rem', ...labelStyle }}>{label}</Typography>}
        />
    )
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onToggle: PropTypes.func,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
}

export default Checkbox