import ApplicationConf from 'conf/ApplicationConf'
import { TimeErrorActionConstant } from 'timeError/reducers/TimeErrorReducer'
import { catchFunctionBuilder, genericPromise2 } from 'utils/ActionUtils'

const TimeErrorAction = {
    fetchTimeErrors: (filter = {}) => dispatch => genericPromise2(ApplicationConf.timeError.list(), { method: 'POST', body: filter })
        .then((list = []) => dispatch(TimeErrorActionConstant.timeErrors(list)))
        .catch(catchFunctionBuilder('Erreur lors de la récupération des time Errors', dispatch)),
}

export default TimeErrorAction