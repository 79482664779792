import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Card, CardContent, Grid, Icon, Tooltip } from '@mui/material'
import { sieauTooltip } from 'utils/FormUtils'
import { isNil } from 'lodash'

const TabList = ({
    defaultTab,
    controlTab,
    setControlTab,
    tabs = [],
    children,

    headerComponent,
}) => {
    const [tab, setTab] = useState(defaultTab ?? tabs[0]?.constant)

    if (isNil(controlTab) + isNil(setControlTab) === 1) { // one is undefined and the other is defined
        // eslint-disable-next-line no-console
        console.error('controlTab and setControlTab must both be defined if you want to control the behavior')
    }

    const currentTab = controlTab ?? tab
    const setCurrentTab = setControlTab ?? setTab

    return (
        <>
            <Grid container justifyContent='flex-end' alignItems='center'>
                <Grid item xs>
                    {headerComponent}
                </Grid>
                <Grid item xs='auto' style={{ marginRight: 5, padding: 0 }}>
                    <Grid container columnSpacing={0.5}>
                        {tabs.map(({ label, icon, constant }) => {
                            const isCurrent = constant === currentTab
                            return (
                                <Tooltip key={constant} title={label}>
                                    <Grid
                                        item
                                        {...sieauTooltip(label)}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: isCurrent ? 'white' : '#f0f0f0',
                                                borderRadius: '8px 8px 0 0',
                                                textAlign: 'center',
                                                display: 'flex',
                                                boxShadow: '0px -1px 2px 0px rgba(0,0,0,0.05)',
                                                cursor: 'pointer',
                                                padding: '10 22',
                                            }}
                                            onClick={() => {
                                                if (!isCurrent) {
                                                    setCurrentTab(constant)
                                                }
                                            }}
                                        >
                                            <Icon fontSize='large' sx={{ color: isCurrent ? 'black' : '#333' }}>{icon}</Icon>
                                        </div>
                                    </Grid>
                                </Tooltip>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>

            <Card>
                <CardContent sx={{ padding: '10', ['&:last-child']: { paddingBottom: '10' } }}>
                    {children(currentTab)}
                </CardContent>
            </Card>
        </>
    )
}

TabList.propTypes = {
    defaultTab: PropTypes.string,
    controlTab: PropTypes.string,
    setControlTab: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })).isRequired,
    children: PropTypes.func.isRequired,
    headerComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    style: PropTypes.shape({}),
}

export default TabList
