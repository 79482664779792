import React from 'react'
import PropTypes from 'prop-types'
import { getTextColorByHexColor } from 'utils/ColorUtil'

const Chip = ({
    label = '',
    color = '',
}) => (
    <span
        style={{
            backgroundColor: color,
            color: getTextColorByHexColor(color),
            padding: '3px 6px',
            borderRadius: '10px',
        }}
    >
        {label}
    </span>
)

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
}

export default Chip