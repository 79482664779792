/* eslint-disable react-hooks/exhaustive-deps */
import { HomeActionConstant } from 'home/reducers/HomeReducer'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

const useTitles = (callback, dependencies = []) => {
    const dispatch = useDispatch()

    const titleMemoize = useMemo(callback, dependencies)

    useEffect(() => {
        dispatch(HomeActionConstant.titles(titleMemoize))
    }, dependencies)
}

export default useTitles