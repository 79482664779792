import { styled } from '@mui/styles'
import { darkBlue } from 'AppTheme'

const Fieldset = styled('fieldset')({
    borderRadius: '5px',
    border: 'solid 1px grey',
    padding: '5 8',
    backgroundColor: 'white',
    margin: '10 0 0 0',
})

const Legend = styled('legend')({
    backgroundColor: 'white',
    border: 'solid grey 1px',
    borderRadius: '5px',
    padding: '0 10',
    color: darkBlue,
    fontWeight: 'bold',
})

const Span = styled('span')({
    lineheight: '12px',
    fontSize: '16px',
})

export { Fieldset, Legend, Span }