import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Masonry } from '@mui/lab'
import React, { useEffect } from 'react'
import { mainBlue } from '../../AppTheme'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { keys } from 'lodash'
import AuthAction from '../actions/AuthAction'
import { useNavigate } from 'react-router-dom'
import useTitles from '../../utils/customHook/useTitles'


const UsersApp = ({

}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        users,
    } = useSelector(store => ({
        users: store.AdminReducer.users,
    }), shallowEqual)

    useEffect(() => {
        dispatch(AuthAction.fetchUsers())
    }, [])

    useTitles(() => [{
        label: 'Utilisateurs',
        href: '/users',
    }], [])

    return (
        <Masonry columns={8} spacing={1}>
            {
                users.map(user => {
                    return (
                        <Card onClick={ () => navigate(`/users/${user.login}`) } style={{ cursor: 'pointer' }}>
                            <CardContent
                                sx={{
                                    padding: '5'
                                }}
                            >
                                <Grid
                                    container
                                    spacing={1}
                                    direction='column'
                                    justify='center'
                                    alignItems='center'
                                    style={{ minHeight: '15vh' }}
                                >
                                    <Grid item style={{ height: 90, width: 90 }}>
                                        <img
                                            src={user.picture}
                                            alt=''
                                            width='100%'
                                            style={ { borderRadius: '15%' } }
                                        />
                                    </Grid>
                                    <Grid item >
                                        <b style={{ fontSize: 15 }}>{ user.login }</b>
                                    </Grid>
                                    <Grid item >
                                        <b style={{ fontSize: 12 }}>{ user.email }</b>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })
            }
        </Masonry>
    )

}

export default UsersApp