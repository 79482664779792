import { padStart } from 'lodash'

const hexToRgb = hex => {
    if (!hex) {
        return
    }
    const rgb = hex.substring(1).match(/.{2}/g).map(x => parseInt(x, 16))
    return `rgb(${rgb.join(',')})`
}

const rgbToHex = rgb => {
    if (!rgb) {
        return
    }
    const rgbPart = rgb.replace('rgb(', '').replace(')', '').split(',')
    const hexPart = rgbPart.map(c => {
        const hex = Number(c).toString(16)
        return padStart(hex, 3, '0')
    }).join('')
    return `#${hexPart}`
}

const getContrast = hexColor => {
    if (!hexColor) {
        return
    }
    const color = hexColor.slice(1)

    const formattedHexColor = color.length === 3 ? color.split('').map(hex => `${hex}${hex}`).join('') : color

    const r = parseInt(formattedHexColor.substr(0, 2), 16)
    const g = parseInt(formattedHexColor.substr(2, 2), 16)
    const b = parseInt(formattedHexColor.substr(4, 2), 16)

    return ((r * 299) + (g * 587) + (b * 114)) / 1000
}

const getTextColorByHexColor = hexColor => {
    if (!hexColor) {
        return 'black'
    }
    const yiq = getContrast(hexColor)

    return (yiq > 128) ? 'black' : 'white'
}

export {
    hexToRgb,
    rgbToHex,
    getTextColorByHexColor,
}
