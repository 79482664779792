import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    isWaiting: false,
}

const store = createSlice({
    name: 'wait',
    initialState,
    reducers: {
        waitStart: (state) => {
            state.isWaiting = true
        },
        waitStop: (state) => {
            state.isWaiting = false
        },
    },
})

export const WaitActionConstant = store.actions
export default store.reducer