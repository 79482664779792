import { createSlice } from '@reduxjs/toolkit'
import DtoTimeError from 'timeError/dto/DtoTimeError'

export const initialState = {
    timeErrors: [],
}

const store = createSlice({
    name: 'timeError',
    initialState,
    reducers: {
        timeErrors: (state, action) => {
            state.timeErrors = action.payload.map(te => new DtoTimeError(te))
        },
        reset: state => {
            state.timeErrors = initialState.timeErrors
        },
    },
})

export const TimeErrorActionConstant = store.actions
export default store.reducer