import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import SupervisionApp from './SupervisionApp'
import DashboardApp from '../../dashboard/components/DashboardApp'
import ServicesApp from '../../service/components/ServicesApp'
import InstancesApp from '../../instance/components/InstancesApp'
import TimeErrorsApp from '../../timeError/components/TimeErrorsApp'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import AuthAction from '../../admin/actions/AuthAction'
import { redirectURI } from '../../utils/FormUtils'
import UsersApp from '../../admin/components/UsersApp'
import UserApp from '../../admin/components/UserApp'

const getPayload = () => {
    const token = localStorage.getItem('aquaparc-token')
    if (token) {
        return atob(token.split('.')[1])
    }
    return ''
}

const AquaparcApp = () => {
    const dispatch = useDispatch()

    const {
        user,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
    }), shallowEqual)

    const payload = getPayload()

    useEffect(() => {
        if (window.location.href.includes('?slackRedirect=true')) {
            const matches = [...window.location.href.matchAll('code=([a-z0-9A-Z\\.]*)')]
            if (matches.length && matches[0].length && matches[0][1]) {
                if (window.location.href.includes('localhost:3002')) {
                    redirectURI(`http://localhost:3002/#/slackRedirect?code=${matches[0][1]}`)
                } else {
                    redirectURI(`https://aquaparc.aquasys.fr/#/slackRedirect?code=${matches[0][1]}`)
                }
            }
        } else if (!user && payload) {
            const login = localStorage.getItem('aquaparc-login')
            if (login) {
                dispatch(AuthAction.fetchUser(login))
            }
        }
    }, [dispatch])

    if (!payload) {
        // setUrlWanted()
        return <Navigate to='/login' />
    }
    const exp = moment(JSON.parse(payload).exp)
    if (moment().isAfter(exp)) {
        // setUrlWanted()
        dispatch(AuthAction.logout())
        return <Navigate to='/login' />
    }

    if (!user) {
        return null
    }

    return (
        <Routes>
            <Route path='/' element={<SupervisionApp />}>
                <Route index element={<DashboardApp />} />
                <Route path='service' element={<ServicesApp />} />
                <Route path='instance' element={<InstancesApp />} />
                <Route path='timeError' element={<TimeErrorsApp />} />
                <Route path='users' element={<UsersApp />} />
                <Route path='users/:login' element={<UserApp />} />
                {/*<Route path='*' element={<NoMatch />} />*/}
            </Route>
        </Routes>
    )
}

export default AquaparcApp