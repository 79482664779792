import { createSlice } from '@reduxjs/toolkit'
import { readLocalStorage, writeLocalStorage } from 'utils/StorageUtil'

export const initialState = {
    titles: [],
    actions: [],
    isMute: readLocalStorage('SOUND_MUTE'),
    user: undefined,
}

const store = createSlice({
    name: 'home',
    initialState,
    reducers: {
        titles: (state, action) => {
            state.titles = action.payload
        },
        actions: (state, action) => {
            state.actions = action.payload
        },
        toggleMute: (state) => {
            writeLocalStorage('SOUND_MUTE', !state.isMute)
            state.isMute = !state.isMute
        },
    },
})

export const HomeActionConstant = store.actions
export default store.reducer