export default class DtoTimeErrorDashboard {
    constructor(obj) {
        this.routingKey = obj.routingKey // String,
        this.url = obj.url // String,
        this.lastInstance = obj.lastInstance // Int,
        this.lastService = obj.lastService // Int,
        this.level = obj.level // Int,
        this.lastRequestStart = obj.lastRequestStart // DateTime,
        this.lastProcessStart = obj.lastProcessStart // DateTime,
        this.lastProcessEnd = obj.lastProcessEnd // DateTime,
        this.lastUser = obj.lastUser // String,
        this.lastModule = obj.lastModule // String
    }
}