import React, { useMemo } from 'react'
import { groupBy, isNil, keys, sum } from 'lodash'
import { round } from '../../utils/NumberUtil'
import { Card, CardContent, CardHeader, Grid, Icon, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import DtoKwaKeUpResult from '../dto/DtoKwaKeUpResult'
import { BRANCH } from 'dashboard/constants/DashboardConstant'
import { Masonry } from '@mui/lab'
import { mainBlue } from 'AppTheme'
import Chip from 'components/Chip'

const getBranchColor = (branch) => {
    switch (branch) {
        case BRANCH.PREPROD: return '#0000FF'
        case BRANCH.PROD: return '#FF0000'
        case BRANCH.MIGRATION_QUALITE: return '#f2ed57' // jaune bière
        case BRANCH.QUALI_PREPROD: return '#800080'
        case BRANCH.DEVELOP: return '#008000'
        default: return '#808080'
    }
}

const TooltipServicesDown = ({
    services = [],
}) => {
    return (
        <Grid container spacing={1} direction='column'>
            {
                services.map((s, i) => (
                    <Grid container item spacing={2} alignItems='center' key={i}>
                        <Grid item xs>
                            {s.name}
                        </Grid>
                        <Grid item xs='auto'>
                            Dernier déploiement: {s.launchDate ?? '???'}
                        </Grid>
                    </Grid>
                ))
            }
        </Grid>
    )
}

TooltipServicesDown.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape({
        apiName: PropTypes.string,
        commitDate: PropTypes.string,
        deployDate: PropTypes.string,
        gitlabName: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        isRunning: PropTypes.bool,
        lastCommit: PropTypes.string,
        launchDate: PropTypes.string,
        memory: PropTypes.string,
        name: PropTypes.string,
        port: PropTypes.number,
    })),
}

const InstanceCard = ({
    instance = {},
    onClick = () => {},
}) => {
    const msDown = instance.services.filter(s => !s.isRunning)
    const isRunning = msDown.length === 0
    const totalMemory = round(sum(instance.services.map(r => parseInt(r.memory || '0'))) / 1000, 1)

    const isIncomplete = instance.services.some(s => [s.lastCommit, s.commitDate, s.deployDate, s.launchDate, s.memory].some(e => isNil(e)))
    const iconColor = isIncomplete ? '#FF4500' : 'black'

    return (
        <Tooltip title={!!msDown.length && <TooltipServicesDown services={msDown} />}>
            <Card sx={{ backgroundColor: isRunning ? 'lightgreen' : '#ff7f7f', cursor: 'pointer' }} onClick={() => onClick({ instance: instance.name })}>
                <CardContent
                    sx={{
                        padding: '5',
                        ['&:last-child']: {
                            paddingBottom: '5',
                        },
                    }}
                >
                    <div style={{ fontWeight: 'bold', display: 'flex', fontSize: '0.9rem' }}>
                        <Tooltip title={isIncomplete ? 'Données incomplètes' : undefined} sx={{ fontSize: '0.9rem' }}>
                            <Icon size='small' sx={{ marginRight: '10px', color: iconColor }}>{isRunning ? 'wb_sunny' : 'thunderstorm'}</Icon>
                        </Tooltip>
                        {`${instance.name} : ${isRunning ? 'UP' : `${msDown.length}KO`}`}
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', fontSize: '0.8rem' }}>
                        {instance.services.length} MS
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', fontSize: '0.8rem' }}>
                        {totalMemory}G
                    </div>

                    {/* <Grid container spacing={0}>
                        <Grid container item xs={12} spacing={0}>
                            <Grid item xs={2}>
                                {isRunning ? <WbSunnyIcon /> : <ThunderstormIcon />}
                            </Grid>
                            <Grid item xs='auto' style={{ fontWeight: 'bold' }}>
                                {`${instance.name} : ${isRunning ? 'UP' : `${msDown.length}KO`}`}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={0}>
                            <Grid item xs={6}>
                                {instance.services.length} MS
                            </Grid>
                            <Grid item xs={6}>
                                {totalMemory}G
                            </Grid>
                        </Grid>
                    </Grid> */}
                </CardContent>
            </Card>
        </Tooltip>
    )
}

InstanceCard.propTypes = {
    instance: PropTypes.shape({
        branch: PropTypes.string,
        name: PropTypes.string,
        services: PropTypes.arrayOf(PropTypes.shape({
            apiName: PropTypes.string,
            commitDate: PropTypes.string,
            deployDate: PropTypes.string,
            gitlabName: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            isRunning: PropTypes.bool,
            lastCommit: PropTypes.string,
            launchDate: PropTypes.string,
            memory: PropTypes.string,
            name: PropTypes.string,
            port: PropTypes.number,
        })),
    }),
    onClick: PropTypes.func,
}

const BranchCard = ({
    branch = BRANCH.UNKNOWN,
    instances = [],
    onClick = () => {},
}) => {
    return (
        <Card sx={{ backgroundColor: '#eee' }}>
            <CardHeader
                title={<Chip label={branch} color={getBranchColor(branch)} />}
                titleTypographyProps={{ variant: 'h6', align: 'center' }}
                onClick={() => onClick({ branch })}
                style={{ cursor: 'pointer' }}
            />
            <CardContent
                sx={{
                    padding: '5',
                    ['&:last-child']: {
                        paddingBottom: '5',
                    },
                }}
            >
                <Grid container spacing={0.5} direction='column'>
                    {
                        instances.map((instance, i) => {
                            return (
                                <Grid item xs='auto' key={i}>
                                    <InstanceCard
                                        instance={instance}
                                        onClick={onClick}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

BranchCard.propTypes = {
    branch: PropTypes.string,
    instances: PropTypes.arrayOf(PropTypes.shape({
        branch: PropTypes.string,
        name: PropTypes.string,
        services: PropTypes.arrayOf(PropTypes.shape({
            apiName: PropTypes.string,
            commitDate: PropTypes.string,
            deployDate: PropTypes.string,
            gitlabName: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            isRunning: PropTypes.bool,
            lastCommit: PropTypes.string,
            launchDate: PropTypes.string,
            memory: PropTypes.string,
            name: PropTypes.string,
            port: PropTypes.number,
        })),
    })),
    onClick: PropTypes.func,
}

const DatacenterCard = ({
    datacenter = {},
    onClick = () => {},
}) => {
    const groupByBranch = groupBy(datacenter.instances, i => i.branch ?? BRANCH.UNKNOWN)
    return (
        <Card sx={{ backgroundColor: mainBlue }}>
            <CardHeader
                title={`${datacenter.server}`}
                titleTypographyProps={{ align: 'center' }}
                onClick={() => onClick({ datacenter: datacenter.server })}
                style={{ cursor: 'pointer', color: 'white' }}
            />
            <CardContent
                sx={{
                    padding: '5',
                    ['&:last-child']: {
                        paddingBottom: '5',
                    },
                }}
            >
                <Grid container spacing={1} direction='column'>
                    {
                        keys(groupByBranch).map((branch, i) => {
                            return (
                                <Grid item xs='auto' key={i}>
                                    <BranchCard
                                        branch={branch}
                                        instances={groupByBranch[branch]}
                                        onClick={onClick}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

DatacenterCard.propTypes = {
    datacenter: PropTypes.shape({
        server: PropTypes.number,
        instances: PropTypes.arrayOf(PropTypes.shape({
            branch: PropTypes.string,
            name: PropTypes.string,
            services: PropTypes.arrayOf(PropTypes.shape({
                apiName: PropTypes.string,
                commitDate: PropTypes.string,
                deployDate: PropTypes.string,
                gitlabName: PropTypes.string,
                id: PropTypes.number,
                isDefault: PropTypes.bool,
                isRunning: PropTypes.bool,
                lastCommit: PropTypes.string,
                launchDate: PropTypes.string,
                memory: PropTypes.string,
                name: PropTypes.string,
                port: PropTypes.number,
            })),
        })),
    }),
    onClick: PropTypes.func,
}

const getBranch = (services = []) => {
    if (services.some(s => s.branch === BRANCH.MIGRATION_QUALITE)) return BRANCH.MIGRATION_QUALITE
    if (services.some(s => s.branch === BRANCH.QUALI_PREPROD)) return BRANCH.QUALI_PREPROD
    if (services.some(s => s.branch === BRANCH.DEVELOP)) return BRANCH.DEVELOP
    if (services.some(s => s.branch === BRANCH.PREPROD)) return BRANCH.PREPROD
    if (services.some(s => s.branch === BRANCH.PROD)) return BRANCH.PROD
    return BRANCH.UNKNOWN
}

// todo prendre en compte le cas ou les services sur une instance ne sont pas sur la même branche, afficher la card de l'instance dans les différentes card de branche
const KwaKeUpMap = ({
    results = [],
    onClick = () => {},
}) => {
    const formattedDatacenters = useMemo(() => {
        const groupByServer = groupBy(results, 'instance.server')
        return keys(groupByServer).map(server => {
            const groupByInstance = groupBy(groupByServer[server], 'instance.name')
            return {
                server: parseInt(server),
                instances: keys(groupByInstance).map(instance => {
                    return {
                        services: groupByInstance[instance].map(s => ({
                            ...s.service,
                            isRunning: s.isRunning,
                            launchDate: s.launchDate,
                            deployDate: s.deployDate,
                            lastCommit: s.lastCommit,
                            commitDate: s.commitDate,
                            port: s.port,
                            memory: s.memory,
                        })),
                        name: instance,
                        branch: getBranch(groupByInstance[instance]),
                    }
                }),
            }
        })
    }, [results])

    return (
        <Masonry columns={8} spacing={1}>
            {
                formattedDatacenters.map((datacenter, i) => {
                    return (
                        <DatacenterCard
                            key={i}
                            datacenter={datacenter}
                            onClick={onClick}
                        />
                    )
                })
            }
        </Masonry>
    )
}

KwaKeUpMap.propTypes = {
    results: PropTypes.arrayOf(PropTypes.instanceOf(DtoKwaKeUpResult)),
    onClick: PropTypes.func,
}

export default KwaKeUpMap
