import { every, isEqual, omit } from 'lodash'

const getLocalStorageJson = (key) => {
    const stored = localStorage.getItem(key)
    if (stored) {
        return JSON.parse(decodeURIComponent(window.atob(localStorage.getItem(key))))
    }
    return null
}

const setLocalStorageJson = (key, obj) => {
    const encoded = window.btoa(encodeURIComponent(JSON.stringify(obj)))
    localStorage.setItem(key, encoded)
}

// todo remove completely
// if content is not a simple string, pass it as a function : const content = () => <myReactContent/>
// that would be more optimized (the react content would be loaded just on the tooltip time)
const sieauTooltip = (/* content, chosenId, position*/) => {
    // const id = chosenId || uuidv4()
    return {
        // onMouseOver: () => setSieauTooltip(id, typeof content === 'function' ? content() : content, position),
        // onMouseOut: () => setSieauTooltip(null),
        // id,
        // onMouseDown: () => setSieauTooltip(null),
    }
}

const isEqualBy = (obj1, obj2, attrs) => every(attrs, att => isEqual(obj1[att], obj2[att]))

const isEqualOmit = (obj1, obj2, attrs) => isEqual(omit(obj1, attrs), omit(obj2, attrs))

const isObject = (object) => {
    return object !== null && typeof object === 'object'
}

const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false
        }
    }

    return true
}


const isValidEmail = (email) => (!!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))

const replace = (list, item, index) => list.map((cur, i) => {
    if (i === index) {
        return item
    }
    return cur
})

const downloadURI = (uri) => {
    const link = document.createElement('a')
    link.href = uri
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const redirectURI = (uri) => {
    const link = document.createElement('a')
    link.href = uri
    // link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export {
    getLocalStorageJson, setLocalStorageJson, replace,
    sieauTooltip, isEqualBy, isEqualOmit,
    isObject, deepEqual, isValidEmail, downloadURI, redirectURI
}
