import { createTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import '@mui/x-date-pickers/themeAugmentation' // bug

const mainBlue = '#35609F'
const secondaryBlue = '#53A1FF'
const darkBlue = '#161832'
const notSoDarkBlue = '#292c4e'
const greyBlue = '#4f88b5'
const lightBlue = '#9BC2E6'
const lightGrey = 'rgb(208, 208, 208)'
const mediumGrey = '#75838F'
const disabledColor = '#949494'
const selectedColor = '#e0e0e0'
const mainWhite = '#fafafa'

const AppTheme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
            grey: greyBlue,
            light: lightBlue,
        },
        secondary: {
            main: secondaryBlue,
        },
        third: {
            main: darkBlue,
        },
        action: {
            disabled: '#9e9e9e',
        },
    },
    typography: {
        fontSize: 12,
    },
    checkbox: {},
    svgIcon: {},
    components: {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '5 10',
                    margin: '0',
                    ['&:last-child']: {
                        paddingBottom: '5',
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '5 10',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                InputLabelProps: {
                    shrink: false,
                },
                color: 'secondary',
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    height: ownerState.label ? '45px' : '26px',

                    '.MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #666',
                    },

                    '.MuiInputBase-root': {
                        height: '26px',
                        top: ownerState.label ? '16px' : undefined,
                        backgroundColor: 'white',
                    },
                    '.MuiInputBase-input': {
                        height: '26px',
                    },


                    '.MuiSelect-select': {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: '5px',
                        height: '1rem',
                    },
                    '.MuiSelect-nativeInput': {
                        margin: 0,
                        padding: 0,
                        height: '26px',
                    },

                    '& .MuiInputBase-input.MuiOutlinedInput-input': {
                        border: 'none',
                        height: '1rem',
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: '5px',
                    },

                    '& .MuiInputLabel-outlined': {
                        top: '-19px',
                        color: ownerState.disabled && '#9e9e9e' || '#161832',
                        fontWeight: 'bold',
                        left: '2px',
                    },
                }),
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        background: 'white',
                    },
                    marginRight: '-10px',
                    padding: '2px',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '2px 0',
                    maxHeight: '250px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: '25px',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
                maxWidth: 'lg',
            },
            styleOverrides: {
                paper: {
                    minHeight: '90vh',
                    maxHeight: '90vh',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: greyBlue,
                    color: 'white',
                    borderBottom: 'solid 1px grey',
                    fontSize: '20px',
                    lineHeight: '20px',
                    padding: '10 24',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f0f0f0',
                    padding: '10',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: 'solid 1px grey',
                },
            },
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                TransitionProps: { unmountOnExit: true, timeout: 250 },
            },
            styleOverrides: {
                root: {
                    borderRadius: '5px',
                    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                },
            },
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: (
                    // eslint-disable-next-line react/react-in-jsx-scope
                    <ExpandMoreIcon sx={{ fontSize: '30px', color: 'white', borderRadius: '50%', border: 'solid white 2px' }}/>
                ),
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    color: 'white',
                    background: mainBlue,
                    borderBottom: 'solid 1px grey',
                    minHeight: 40,
                    height: 40,
                    fontSize: '15px',
                    fontWeight: 'bold',
                    borderRadius: ownerState.expanded ? '5px 5px 0 0' : '5px',
                }),
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderRadius: '0 0 5px 5px',
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    maxWidth: 300,
                    fontSize: '0.9rem',
                    backgroundColor: notSoDarkBlue,
                },
                arrow: {
                    color: notSoDarkBlue,
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
                'aria-label': 'table',
            },
        },
        // MuiTableRow: {
        //     styleOverrides: {
        //         root: {
        //             '&:last-child td, &:last-child th': { border: 0 },
        //         },
        //     },
        // },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    borderColor: 'black',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    cursor: 'pointer',
                    paddingRight: '32px',
                    textWrap: 'nowrap',
                },
                body: {
                    fontSize: '13px',
                    fontWeight: 'normal',
                    padding: '1 16',
                    backgroundColor: 'white',
                    textWrap: 'nowrap',
                },
            },
            variants: [
                {
                    props: { variant: 'bodyIcon' },
                    style: {
                        padding: '1',
                        width: '32px',
                        cursor: 'pointer',
                    },
                },
                {
                    props: { variant: 'headerIcon' },
                    style: {
                        borderColor: 'black',
                        cursor: 'pointer',
                        paddingRight: '16px',
                    },
                },
            ],
        },
    },
})

export default AppTheme
export {
    notSoDarkBlue,
    mainBlue,
    secondaryBlue,
    darkBlue,
    greyBlue,
    lightBlue,
    lightGrey,
    mediumGrey,
    disabledColor,
    selectedColor,
    mainWhite,
}