import { Dialog, DialogContent, Grid } from '@mui/material'
import { Fieldset } from 'components/form/Fieldset'
import { CardTable } from 'components/datatable/Table'
import ConfirmModal from 'components/modal/ConfirmModal'
import Checkbox from 'components/form/Checkbox'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import Input from 'components/form/Input'
import ApplicationConf from 'conf/ApplicationConf'
import InstanceAction from 'instance/actions/InstanceAction'
import { isUndefined } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useFetch from 'utils/customHook/useFetch'
import useTitles from 'utils/customHook/useTitles'
import PropTypes from 'prop-types'
import { NEW } from 'components/action/ActionConstant'
import { greyBlue } from 'AppTheme'
import DtoInstance from 'instance/dto/DtoInstance'

const formatResult = list => list.map(i => new DtoInstance(i))

const PopinInstance = ({
    isOpen = false,
    close = () => {},
    reload = () => {},
    instance = {},
}) => {
    const dispatch = useDispatch()

    const {
        value: isLoading,
        setTrue: loading,
        setFalse: notLoading,
    } = useBoolean(false)

    const {
        value: isChainCreate,
        toggle: toggleChainCreate,
    } = useBoolean(false)

    const [name, setName] = useState()
    const [server, setServer] = useState()
    const [instanceType, setInstanceType] = useState()
    const [url, setUrl] = useState()

    useEffect(() => {
        if (isOpen) {
            setName(instance.name)
            setServer(instance.server)
            setInstanceType(instance.instanceType)
            setUrl(instance.url)
        }
    }, [instance.instanceType, instance.name, instance.server, instance.url, isOpen])

    const resetAllField = () => {
        setName()
        setServer()
        setInstanceType()
        setUrl()
    }

    const dispatchPromise = () => {
        if (isUndefined(instance.id)) {
            return dispatch(InstanceAction.createInstance({ id: -1, name, server, instanceType, url }))
        }
        return dispatch(InstanceAction.updateInstance({ id: instance.id, name, server, instanceType, url }))
    }

    const validate = () => {
        loading()
        dispatchPromise()
            .then(res => {
                if (!res) {
                    return
                }
                reload()
                if (!isChainCreate) {
                    close()
                } else {
                    resetAllField()
                }
            })
            .finally(notLoading)
    }

    return (
        <Dialog
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: 'unset',
                    maxHeight: 'unset',
                },
            }}
        >
            <BasicDialogTitle close={close}>
                Instance
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Fieldset>
                    <Grid container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Input
                                label={'Nom'}
                                value={name}
                                onChange={setName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                label={'Serveur'}
                                value={server}
                                onChange={setServer}
                                type='float'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                label={'Type d\'instance'}
                                value={instanceType}
                                onChange={setInstanceType}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                label={'Url'}
                                value={url}
                                onChange={setUrl}
                            />
                        </Grid>
                    </Grid>
                </Fieldset>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={isUndefined(instance.id) ? 'Créer' : 'Modifier'}
                validate={validate}
                isLoading={isLoading}
            >
                {
                    isUndefined(instance.id) && (
                        <Checkbox
                            label='Saisir une autre instance'
                            checked={isChainCreate}
                            onToggle={toggleChainCreate}
                            labelStyle={{ color: '#9e9e9e', fontWeight: 'normal' }}
                        />
                    )
                }
            </BasicDialogAction>
        </Dialog>
    )
}

PopinInstance.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    reload: PropTypes.func,
    instance: PropTypes.shape({
        name: PropTypes.string,
        server: PropTypes.number,
        instanceType: PropTypes.string,
        url: PropTypes.string,
    }),
}

const InstancesApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        value: isOpenPopin,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: openConfirm,
        setFalse: closeConfirm,
    } = useBoolean(false)

    useTitles(() => [{
        label: 'Instance',
        href: '/instance',
    }], [])

    const [selectedInstance, setSelectedInstance] = useState()

    const {
        data = [],
        reload,
    } = useFetch(ApplicationConf.instance.all(), undefined, { formatResult })

    const formattedInstances = data.map(instance => ({
        id: instance.id,
        name: { value: instance.name },
        server: { value: instance.server },
        instanceType: { value: instance.instanceType },
        url: {
            value: instance.url,
            fontColor: greyBlue,
            onClick: instance.url ? () => window.open(instance.url, '_blank') : undefined,
        },
    }))

    useActions(() => [{
        type: NEW,
        onClick: openPopin,
    }], [])

    const instance = useMemo(() => {
        return data.find(i => i.id === selectedInstance) ?? {}
    }, [data, selectedInstance])

    return (
        <div style={{ margin: '10', paddingBottom: '50px' }}>
            <CardTable
                title={'Instances'}

                rows={formattedInstances}
                headers={['name', 'server', 'instanceType', 'url']}
                headersLabel={{
                    name: 'Name',
                    server: 'Serveur',
                    instanceType: 'Type d\'instance',
                    url: 'Url',
                }}

                actions={[{
                    icon: 'replay',
                    onClick: reload,
                    tooltip: 'Recharger',
                }]}

                onDelete={row => {
                    setSelectedInstance(row.id)
                    openConfirm()
                }}
                onEdit={row => {
                    setSelectedInstance(row.id)
                    openPopin()
                }}
            />
            <PopinInstance
                isOpen={isOpenPopin}
                close={() => {
                    setSelectedInstance()
                    closePopin()
                }}
                reload={reload}
                instance={instance}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title='Êtes-vous sûr de vouloir supprimer cette instance ?'
                onValidate={() => {
                    dispatch(InstanceAction.deleteInstance(selectedInstance)).finally(() => {
                        reload()
                        setSelectedInstance()
                        closeConfirm()
                    })
                }}
                onClose={closeConfirm}
            />
        </div>
    )
}

export default InstancesApp