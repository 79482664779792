/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AquaKwak from './AquaKwak'

class BoundaryError extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidUpdate = prevProps => {
        if (this.props.url !== prevProps.url && this.state.hasError) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: false })
        }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch = ({ stack }) => {
        // eslint-disable-next-line no-console
        console.error(stack)
    }

    render = () => {
        if (this.state.hasError) {
            return <AquaKwak/>
        }
        return this.props.children
    }
}

BoundaryError.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    error: PropTypes.func,
    url: PropTypes.string,
}

const mapStateToProps = store => ({
    url: store.router.location.pathname,
})

export default connect(mapStateToProps)(BoundaryError)