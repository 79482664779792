import { createSlice } from '@reduxjs/toolkit'
import DtoService from 'service/dto/DtoService'

export const initialState = {
    services: [],
}

const store = createSlice({
    name: 'service',
    initialState,
    reducers: {
        services: (state, action) => {
            state.services = action.payload.map(s => new DtoService(s))
        },
        reset: state => {
            state.services = initialState.services
        },
    },
})

export const ServiceActionConstant = store.actions
export default store.reducer