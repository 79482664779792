import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    user: undefined,
    users: [],
}

const store = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
    },
})

export const AdminActionConstant = store.actions
export default store.reducer