import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Card, Grid } from '@mui/material'
import AuthAction from '../actions/AuthAction'
import { useNavigate, useParams } from 'react-router-dom'
import DtoUser from '../dto/DtoUser'
import Checkbox from '../../components/form/Checkbox'
import useTitles from '../../utils/customHook/useTitles'
import useActions from '../../utils/customHook/useActions'
import { SAVE } from '../../components/action/ActionConstant'
import { ADMIN, LICENCES, SUPERVISION } from '../HabConstants'


const UserApp = ({

}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { login } = useParams()
    const [user, setUser] = useState([])

    useEffect(() => {
        AuthAction.promiseUser(login).then(u => setUser(new DtoUser(u)))
    }, [])

    useTitles(() => [{
        label: 'Utilisateurs',
        href: '/users',
    }, {
        label: login,
        href: `/users/${login}`,
    }], [])

    useActions(() => [{
        type: SAVE,
        onClick: () => dispatch(AuthAction.updateUserHabs(login, user.habs)),
    }], [user])

    const changeHab = hab => {
        if (user.habs.includes(hab)) {
            setUser({ ...user, habs: user.habs.filter(h => h !== hab) })
        } else {
            setUser({ ...user, habs: [...user.habs, hab] })
        }
    }

    if (!user) {
        return null
    }

    return (
        <Card>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <img
                        src={user.picture}
                        alt=''
                        width='50%'
                        style={ { borderRadius: '15%' } }
                    />
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <b style={{ fontSize: 15 }}>{ user.login }</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b style={{ fontSize: 15 }}>{ user.email }</b>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Checkbox label='Supervision' checked={user?.habs?.includes(SUPERVISION)} onToggle={ () => changeHab(SUPERVISION) } />
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox label='Admin' checked={user?.habs?.includes(ADMIN)} onToggle={ () => changeHab(ADMIN) } />
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox label='Licences' checked={user?.habs?.includes(LICENCES)} onToggle={ () => changeHab(LICENCES) } />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )

}

export default UserApp