import { CardTable } from 'components/datatable/Table'
import { DESC } from 'components/datatable/TableConstant'
import ApplicationConf from 'conf/ApplicationConf'
import InstanceAction from 'instance/actions/InstanceAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ServiceAction from 'service/actions/ServiceAction'
import TimeErrorFilterFields from 'timeError/components/TimeErrorFilterFields'
import { getDuration, getLevelColor } from 'timeError/constants/TimeErrorConstant'
import DtoTimeErrorDashboard from 'timeError/dto/DtoTimeErrorDashboard'
import { getFullDate, shortenHumanize } from 'utils/DateUtil'
import useFetch from 'utils/customHook/useFetch'
import useListIndexed from 'utils/customHook/useListIndexed'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'

const formatResult = list => list.map(te => new DtoTimeErrorDashboard(te))

const TimeErrorTable = () => {
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({})
    const {
        data: timeErrors = [],
        reload,
    } = useFetch(ApplicationConf.timeError.dashboard(), { method: 'POST', body: filter }, { formatResult })

    useEffect(() => {
        dispatch(ServiceAction.fetchServices())
        dispatch(InstanceAction.fetchInstances())
    }, [dispatch])

    const {
        services,
        instances,
    } = useSelector(store => ({
        services: store.ServiceReducer.services,
        instances: store.InstanceReducer.instances,
    }), shallowEqual)

    const indexedInstances = useListIndexed(instances)
    const indexedServices = useListIndexed(services)

    useUpdateEffect(() => {
        reload()
    }, [filter])

    const formattedTimeErrors = useMemo(() => {
        return timeErrors.map(te => {
            const duration = getDuration({ level: te.level, requestStart: te.lastRequestStart, processStart: te.lastProcessStart, processEnd: te.lastProcessEnd })
            return {
                routingKey: { value: te.routingKey, color: getLevelColor(te.level) },
                url: { value: te.url },
                lastInstance: { value: indexedInstances[te.lastInstance]?.name },
                lastService: { value: indexedServices[te.lastService]?.name },
                lastRequestStart: { value: getFullDate(te.lastRequestStart), sortValue: te.lastRequestStart },
                lastProcessStart: { value: getFullDate(te.lastProcessStart), sortValue: te.lastProcessStart },
                lastProcessEnd: { value: getFullDate(te.lastProcessEnd), sortValue: te.lastProcessEnd },
                duration: { value: shortenHumanize(duration), sortValue: duration },
                lastUser: { value: te.lastUser },
                lastModule: { value: te.lastModule },
            }
        })
    }, [indexedInstances, indexedServices, timeErrors])

    return (
        <>
            <TimeErrorFilterFields
                defaultFilter={filter}
                onValidate={setFilter}
            />
            <CardTable
                title={'Time errors'}

                rows={formattedTimeErrors}
                headers={['routingKey', 'url', 'lastInstance', 'lastService', 'lastRequestStart', 'lastProcessStart', 'lastProcessEnd', 'duration', 'lastUser', 'lastModule']}
                headersLabel={{
                    routingKey: 'Routing key',
                    url: 'URL',
                    lastInstance: 'Instance',
                    lastService: 'Service',
                    lastRequestStart: 'Début de la requête',
                    lastProcessStart: 'Début du traitement',
                    lastProcessEnd: 'Fin du traitement',
                    duration: 'Duré',
                    user: 'utilisateur',
                    lastModule: 'module',
                }}

                actions={[{
                    icon: 'replay',
                    onClick: reload,
                    tooltip: 'Recharger',
                }]}

                defaultSort={{ sortColumn: ['lastRequestStart'], sortDirection: { lastRequestStart: DESC } }}
            />
        </>
    )
}

export default TimeErrorTable