import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getDate } from '../../utils/DateUtil'
import KwaKeUpMap from './KwaKeUpMap'
import { Grid, Icon } from '@mui/material'
import ApplicationConf from 'conf/ApplicationConf'
import TabList from 'components/tab/TabList'
import useTitles from 'utils/customHook/useTitles'
import useFetchTimeout from 'utils/customHook/useFetchTimeout'
import { IDLE, REJECTED } from 'store/StoreConstants'
import DtoKwaKeUpResult from '../dto/DtoKwaKeUpResult'
import useTimeout from 'utils/customHook/useTimeout'
import moment from 'moment'
import KwakeUpTable from './KwakeUpTable'
import TimeErrorTable from './TimeErrorTable'
import UnupdatedTable from './UnupdatedTable'
import useActions from 'utils/customHook/useActions'
import { SOUND } from 'components/action/ActionConstant'
import { differenceWith, uniqBy } from 'lodash'
import soundCaBugNormal from 'assets/sound/caBugNormal.mp3'
import soundCKC from 'assets/sound/ckc.mp3'
import soundCToutRouge from 'assets/sound/cToutRouge.mp4'
import soundGVu from 'assets/sound/gVu.mp4'
import soundCTropCalme from 'assets/sound/cTropCalme.mp4'
import soundCOk from 'assets/sound/cOk.mp4'
import soundHouston from 'assets/sound/houstononaunpbm.mp4'
import soundSerge from 'assets/sound/serge.mp3'
import { shallowEqual, useSelector } from 'react-redux'
import useSound from 'utils/customHook/useSound'

const MAP = 'MAP'
const TABLE = 'TABLE'
const UNUPDATED_TABLE = 'UNUPDATED_TABLE'
const TIME_ERROR = 'TIME_ERROR'

const Clock = () => {
    const [time, setTime] = useState([])
    const { reset } = useTimeout(() => {
        setTime(moment().format('DD/MM/YYYY HH:mm:ss'))
    }, 1000)

    reset()
    return <>Heure : {time}</>
}

const Notification = ({
    results = [],
}) => {
    const [playCToutRouge] = useSound(soundCToutRouge)
    const [playCKC] = useSound(soundCKC)
    const [playCaBugNormal] = useSound(soundCaBugNormal)
    const [playGVu] = useSound(soundGVu)
    const [playCTropCalme] = useSound(soundCTropCalme)
    const [playCOk] = useSound(soundCOk)
    const [playHouston] = useSound(soundHouston)
    const [playSerge] = useSound(soundSerge)

    const {
        isMute,
    } = useSelector(store => ({
        isMute: store.HomeReducer.isMute,
    }), shallowEqual)
    const [prevError, setPrevError] = useState([])
    const [dateLastError, setDateLastError] = useState(moment())

    useEffect(() => {
        if (isMute) return

        const errors = results.filter(r => !r.isRunning)
        const newError = differenceWith(errors, prevError, (e, p) => e.instance.id === p.instance.id && e.service.id === p.service.id)
        setPrevError(errors)

        if (!errors.length && prevError.length) {
            if (Math.random() < 0.01) playSerge()
            else playCOk()
        }

        if (!newError.length) {
            const now = moment()
            const time = moment.duration(now.diff(dateLastError)).valueOf()
            if (time > 3600000) { // 1h
                setDateLastError(now)
                playCTropCalme()
            }
        }

        if (newError.length) {
            setDateLastError(moment().valueOf())
            const uniqServer = uniqBy(newError, 'instance.server').map(e => e.instance.server)
            const isServerError = uniqServer.some(server => {
                const allServerError = errors.filter(e => e.instance.server === server)
                const allService = results.filter(r => r.instance.server === server)
                return allServerError.length === allService.length
            })
            if (isServerError) { // crash server
                playCToutRouge()
                return
            }

            const uniqInstance = uniqBy(newError, 'instance.id').map(e => e.instance.id)
            const isInstanceError = uniqInstance.some(instance => {
                const allInstanceError = errors.filter(e => e.instance.id === instance)
                const allService = results.filter(r => r.instance.id === instance)
                return allInstanceError.length === allService.length
            })
            if (isInstanceError) { // crash instance
                playCKC()
                return
            }

            // todo add branch info on error
            const rand = Math.random()
            if (rand < 0.80) playHouston()
            else if (rand < 0.95) playCaBugNormal()
            else playGVu()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results])

    return
    // return (
    //     <Grid container spacing={1} style={{ paddingBottom: '20px' }}>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playCToutRouge}>CToutRouge</Button></Grid>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playCKC}>CKC</Button></Grid>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playCaBugNormal}>CaBugNormal</Button></Grid>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playGVu}>gVu</Button></Grid>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playCTropCalme}>cTropCalme</Button></Grid>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playCOk}>cOk</Button></Grid>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playSerge}>serge</Button></Grid>
    //         <Grid item xs='auto'><Button variant='contained' color='primary' onClick={playHouston}>houston</Button></Grid>
    //     </Grid>
    // )
}

Notification.propTypes = {
    results: PropTypes.arrayOf(PropTypes.instanceOf(DtoKwaKeUpResult)),
}

const DashboardApp = ({

}) => {
    const [tab, setTab] = useState(MAP)
    const [filter, setFilter] = useState({})

    useActions(() => [{
        type: SOUND,
    }], [])

    useTitles(() => [{
        label: 'Dashboard',
        href: '/',
    }], [])

    const {
        data: results = [],
        status,
        resultDate,
        reset,
        clear,
    } = useFetchTimeout(ApplicationConf.pingState(), undefined, 15000, { skipLoadingState: true, formatResult: res => res.map(r => new DtoKwaKeUpResult(r)) })

    useEffect(() => {
        if (tab === TIME_ERROR) {
            clear()
            return
        }
        reset()
    }, [tab])

    return (
        <div style={{ margin: '10', paddingBottom: '20px' }}>
            <Notification results={results}/>
            <TabList
                controlTab={tab}
                setControlTab={setTab}
                tabs={[
                    {
                        constant: MAP,
                        label: 'Cartographie',
                        icon: 'map',
                    },
                    {
                        constant: TABLE,
                        label: 'Tableau',
                        icon: 'table_view',
                    },
                    {
                        constant: UNUPDATED_TABLE,
                        label: 'Tableau services non mis à jour',
                        icon: 'playlist_remove',
                    },
                    {
                        constant: TIME_ERROR,
                        label: 'Time error',
                        icon: 'running_with_errors',
                    },
                ]}
                headerComponent={resultDate && (
                    <Grid container spacing={3}>
                        <Grid container item xs='auto' direction='column' sx={{ marginLeft: '10px' }}>
                            <Grid item>
                                <Clock />
                            </Grid>
                            <Grid item>
                                Actualisé le {getDate(resultDate)} à {getDate(resultDate, 'HH:mm:ss')}
                            </Grid>
                        </Grid>
                        <Grid container item xs='auto'>
                            {
                                status === REJECTED && (
                                    <>
                                        <Icon sx={{ marginRight: '10px', color: 'red' }}>warning</Icon>
                                        Impossible de récupérer l'état des serveurs
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                )}
            >
                {t => {
                    if (status === IDLE) {
                        return (
                            <div>
                                Chargement en cours...
                            </div>
                        )
                    }
                    if (t === TIME_ERROR) {
                        return (
                            <TimeErrorTable />
                        )
                    }
                    if (results.length === 0) {
                        return (
                            <div>
                                Aucune donnée à afficher
                            </div>
                        )
                    }
                    return (
                        <>
                            {t === MAP && (
                                <KwaKeUpMap
                                    results={results}
                                    onClick={info => {
                                        setFilter(info)
                                        setTab(TABLE)
                                    }}
                                />
                            )}
                            {t === TABLE && (<KwakeUpTable results={results} filter={filter} setFilter={setFilter} />)}
                            {t === UNUPDATED_TABLE && (<UnupdatedTable results={results} />)}
                        </>
                    )
                }}
            </TabList>
        </div>
    )
}

export default DashboardApp
