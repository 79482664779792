// Choisir une des deux usedUrl ci dessous

// A utiliser pour le serveur
const myUrl = (window.location.href || document.URL).split('#')[0]
const url = 'https://aquaparc.aquasys.fr/'

const usedUrl = myUrl.includes('localhost') ? url : myUrl

// ////////////////////////////////////////////////////////////
const pathApi = `${usedUrl}${usedUrl.endsWith('/') ? '' : '/'}api`

export { pathApi, myUrl, usedUrl }
