import moment from 'moment'

// todo use constant for color
const getLevelColor = lvl => {
    switch (lvl) {
        case 1: return '#F7F76A'
        case 2: return '#FF8300'
        case 3: return '#F44336'
        case 4: return '#8B0000'
        default: return undefined
    }
}

const getDuration = te => {
    const {
        level,
        requestStart,
        processStart,
        processEnd,
    } = te
    const rs = moment(requestStart)
    const ps = moment(processStart)
    const pe = moment(processEnd)
    switch (level) {
        case 1:
        case 2: return moment.duration(pe.diff(ps)).valueOf()
        case 3:
        case 4: return moment.duration(pe.diff(rs)).valueOf()
        default: return undefined
    }
}

export {
    getLevelColor,
    getDuration,
}