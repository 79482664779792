import React, { useMemo } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { BasicDialogTitle } from 'components/modal/DialogComponents'
import ReactECharts from 'echarts-for-react'
import { shallowEqual, useSelector } from 'react-redux'
import { getDuration } from 'timeError/constants/TimeErrorConstant'
import { getDate, getFullDate, shortenHumanize } from 'utils/DateUtil'
import useListIndexed from 'utils/customHook/useListIndexed'
import { Fieldset } from 'components/form/Fieldset'
import { maxBy, minBy, orderBy } from 'lodash'
import DtoTimeError from 'timeError/dto/DtoTimeError'
import PropTypes from 'prop-types'

const Graph = ({
    timeErrors = [],
}) => {
    const interval = timeErrors.length ? maxBy(timeErrors, 'requestStart')?.requestStart - minBy(timeErrors, 'requestStart')?.requestStart : 0
    const options = {
        series: [{
            type: 'line',
            data: orderBy(timeErrors, 'requestStart').map(te => [te.requestStart, getDuration(te)]),
            smooth: true,
        }],
        xAxis: [{
            type: 'time',
            axisLabel: {
                formatter: v => getDate(v),
            },
            splitLine: { show: true },
            axisLine: { show: true },
            axisTick: { show: true },
            min: ({ min }) => min - interval * 0.05,
            max: ({ max }) => max + interval * 0.05,
        }],
        yAxis: [{
            type: 'time',
            axisLabel: {
                formatter: shortenHumanize,
            },
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: { show: true },
            min: 0,
            max: ({ max }) => max * 1.05,
        }],
        grid: { top: 20, right: 20, bottom: 20, left: 60, height: 360 },
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const {
                    marker,
                    data: [date, duration],
                } = params[0]
                return `${getFullDate(date)}<br />${marker}${shortenHumanize(duration)}`
            },
        },
    }
    return (
        <ReactECharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: 400 }}
        />
    )
}

Graph.propTypes = {
    timeErrors: PropTypes.arrayOf(PropTypes.instanceOf(DtoTimeError)),
}

const PopinGraphTimeErrors = ({
    open = false,
    onClose = () => { },
    filters = {},
}) => {
    const {
        timeErrors,
        instances,
    } = useSelector(store => ({
        timeErrors: store.TimeErrorReducer.timeErrors,
        instances: store.InstanceReducer.instances,
    }), shallowEqual)

    const indexedInstances = useListIndexed(instances)

    // todo call instead of filter
    const filteredTimeErrors = useMemo(() => {
        const {
            routingKey,
            instance,
        } = filters
        return timeErrors.filter(te => te.routingKey === routingKey && te.instance === instance)
    }, [filters, timeErrors])

    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    minHeight: 'unset',
                    maxHeight: 'unset',
                },
            }}
        >
            <BasicDialogTitle close={onClose}>
                {`${indexedInstances[filters.instance]?.name}: ${filters.routingKey}`}
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Fieldset>
                    <Graph timeErrors={filteredTimeErrors} filters={filters} />
                </Fieldset>
            </DialogContent>
        </Dialog>
    )
}

PopinGraphTimeErrors.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    filters: PropTypes.shape({
        routingKey: PropTypes.string,
        instance: PropTypes.number,
    }),
}

export default PopinGraphTimeErrors