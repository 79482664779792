const smallestReal = -3.402E+38
const largestReal = 3.402E+38

const round = (number = 0, precision = 2) => {
    const factor = Math.pow(10, precision)
    return Math.round(number * factor) / factor
}

const nFormatter = (numS) => {
    const num = parseInt(numS)
    if (num >= 1000000000) {
        return `${round(num / 1000000000).toString().replace(/\.0$/, '')}G`
    }
    if (num >= 1000000) {
        return `${round(num / 1000000).toString().replace(/\.0$/, '')}M`
    }
    if (num >= 1000) {
        return `${round(num / 1000).toString().replace(/\.0$/, '')}K`
    }
    return numS
}

const fixedNumber = (number, fixed = 2) => {
    if (number) {
        return Number(number).toFixed(fixed)
    }
    return 0
}

const truncateDecimals = (value, decimals = 0) => {
    const parts = value.toString().split('.')

    if (parts.length === 2) {
        return Number([parts[0], parts[1].slice(0, decimals)].join('.'))
    }
    return Number(parts[0])
}

const getNumberFormat = (number) => {
    return Number(number).toLocaleString('fr-FR')
}

const getLongNumber = (number) => {
    return nFormatter(number)
}

const formatNumber = (number) => {
    return number.toFixed(3)
}

const formatFixedNumber = (number, fxdNumber) => {
    return number.toFixed(fxdNumber)
}

const getSuperiorAround = (value) => {
    return Math.ceil(value)
}
const getInferiorAround = (value) => {
    return Math.floor(value)
}

const getPercentage = (nb, total) => nb && total ? Math.round(nb / total * 100) : 0

const hasValue = (value) => {
    return !!value || value === 0 || value === '0' || value === false
}

const hasBooleanValue = (value) => {
    return value === true || value === false
}

const nbElements = (nb) => ` (${nb} ${nb ? 'Eléments' : 'Elément'})`

const groupSameSuccessiveValues = (orderedValues, key) => {
    return orderedValues.reduce((acc, measure) => {
        const newAcc = acc.slice()
        const current = newAcc[0]
        if (!current.length) {
            newAcc[0] = [measure]
        } else {
            const lastOne = current[0]
            if (lastOne[key] === measure[key]) {
                newAcc[0] = [measure, ...current]
            } else {
                return [[measure, lastOne], ...newAcc]
            }
        }
        return newAcc
    }, [[]])
}

export {
    round,
    truncateDecimals,
    fixedNumber,
    getNumberFormat,
    getLongNumber,
    nFormatter,
    formatNumber,
    formatFixedNumber,
    getSuperiorAround,
    getInferiorAround,
    hasValue,
    hasBooleanValue,
    getPercentage,
    nbElements,
    groupSameSuccessiveValues,
    smallestReal,
    largestReal,
}
