import AppStore from '../store/AppStore'

const hasHab = (hab) => (AppStore.getState().AdminReducer.user?.habs ?? []).includes(hab)

// const componentHasHabilitations = (habs) => {
//     const accountUser = AppStore.getState().AccountReducer.accountUser
//     const accountHabilitations = AppStore.getState().AccountReducer.accountHabilitations
//     const requiredValid = habs.required.every(c => accountHabilitations.map(a => a.habilitation).includes(c))
//     const atLeastOneValid = !habs.atLeastOne.length || habs.atLeastOne.some(c => accountHabilitations.map(a => a.habilitation).includes(c))
//     const requiredRoles = !habs.requiredRoles.length || habs.requiredRoles.some(c => (
//         c === ADMIN && accountUser.isAdmin === '1' ||
//         c === GEST && accountUser.metadata === '1' ||
//         c === TECH && accountUser.isTechnicien === '1' ||
//         c === CONSULT && accountUser.consultant === '1'
//     ))
//     const forbiddenRoles = !habs.forbiddenRoles.some(c => (
//         c === ADMIN && accountUser.isAdmin === '1' ||
//         c === GEST && accountUser.metadata === '1' ||
//         c === TECH && accountUser.isTechnicien === '1' ||
//         c === CONSULT && accountUser.consultant === '1'
//     ))
//     return requiredValid && atLeastOneValid && requiredRoles && forbiddenRoles
// }
//
// const isAuthorized = (hab, store) => {
//     const {
//         accountUser,
//         accountHabilitations,
//     } = store.AccountReducer
//     const requiredValid = hab.required.every(c => accountHabilitations.map(a => a.habilitation).includes(c))
//     const atLeastOneValid = !hab.atLeastOne.length || hab.atLeastOne.some(c => accountHabilitations.map(a => a.habilitation).includes(c))
//     const requiredRoles = !hab.requiredRoles.length || hab.requiredRoles.some(c => (
//         c === ADMIN && accountUser.isAdmin === '1' ||
//         c === GEST && accountUser.metadata === '1' ||
//         c === TECH && accountUser.isTechnicien === '1' ||
//         c === CONSULT && accountUser.consultant === '1'
//     ))
//     const forbiddenRoles = !hab.forbiddenRoles.some(c => (
//         c === ADMIN && accountUser.isAdmin === '1' ||
//         c === GEST && accountUser.metadata === '1' ||
//         c === TECH && accountUser.isTechnicien === '1' ||
//         c === CONSULT && accountUser.consultant === '1'
//     ))
//     return requiredValid && atLeastOneValid && requiredRoles && forbiddenRoles
// }
//
// const authorizeFilter = (list, user, selected, elemKey = 'contributorCode') => {
//     if (user.isAdmin === '1' || !hasValue(user.contributorCode)) {
//         return list
//     }
//     return list.filter(elem => !hasValue(elem[elemKey]) || elem[elemKey] === user.contributorCode || elem.id === selected)
// }
//
// const authorizeContributors = (contributors, user, selected) => {
//     if (user.isAdmin === '1' || !hasValue(user.contributorCode)) {
//         return contributors
//     }
//     return contributors.filter(({ contributorCode, id }) => !hasValue(contributorCode) || contributorCode === user.contributorCode || id === user.contributorCode || id === selected)
// }

export { hasHab }

