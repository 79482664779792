import { createSlice } from '@reduxjs/toolkit'
import DtoInstance from 'instance/dto/DtoInstance'

export const initialState = {
    instances: [],
}

const store = createSlice({
    name: 'instance',
    initialState,
    reducers: {
        instances: (state, action) => {
            state.instances = action.payload.map(i => new DtoInstance(i))
        },
        reset: state => {
            state.instances = initialState.instances
        },
    },
})

export const InstanceActionConstant = store.actions
export default store.reducer