export default class DtoUser {
    constructor(obj) {
        this.login = obj.login
        this.passwordHash = obj.passwordHash
        this.token = obj.token
        this.resetPassword = obj.resetPassword
        this.updateDate = obj.updateDate
        this.loginMaj = obj.loginMaj
        this.connexionAttempts = obj.connexionAttempts
        this.email = obj.email
        this.picture = obj.picture
        this.habs = obj.habs ?? []
    }
}